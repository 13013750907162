<form novalidate [formGroup]="diaryForm">
    <div class="modal-header">
        <div class="row justify-content-between align-items-center">
            <div class="col-md-auto">
                <h4 class="modal-title">DiaryId :{{diaryId}} </h4>
            </div>
            <div class="col-md-auto">
                <span class="material-symbols-outlined cursor-pointer" (click)="closeDialog()">
                    close
                </span>
            </div>
        </div>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="form-group col-md-6">
                <label>Type</label>
                <label class="form-control custom-disable">{{diaryType}}</label>
            </div>
            <div class="form-group col-md-6">
                <label>Action Date</label>


                <kendo-datepicker onkeydown="return false" onclick="return false" class="w-100" [format]="'dd/MM/yyyy'"
                    formControlName="actionDate"></kendo-datepicker>

                <div class="validation-msg-label"
                    *ngIf="diaryForm.controls.actionDate.touched  && diaryForm.controls.actionDate.errors?.required">
                    Required
                </div>
            </div>
            <div class="form-group col-md-6">
                <label>Assigned To</label>
                <kendo-combobox class="w-100" [data]="listAssignedUser" [textField]="'text'" [valueField]="'value'"
                    formControlName="assignUserId" valuePrimitive="true" [filterable]="true"  #assignUserId [openOnFocus]="assignUserId" 
                    (filterChange)="handleFilter($event)">
                </kendo-combobox>

                <!-- (filterChange)="handleFilterAgentType($event)" -->


                <div class="validation-msg-label"
                    *ngIf="diaryForm.controls.assignUserId.touched  && diaryForm.controls.assignUserId.errors?.required">
                    Required
                </div>
            </div>
            <div class="form-group col-md-6">
                <label>Status</label>
                <kendo-combobox class="w-100" [data]="listDiaryStatus" [textField]="'text'" [valueField]="'value'"
                    formControlName="diaryStatus" valuePrimitive="true"  #diaryStatus [openOnFocus]="diaryStatus" 
                    [filterable]="true"  (filterChange)="statusFilter($event)">
                </kendo-combobox>
                <div class="validation-msg-label"
                    *ngIf="diaryForm.controls.diaryStatus.touched  && diaryForm.controls.diaryStatus.errors?.required">
                    Required
                </div>
            </div>
            <div class="form-group col-md-12" *ngIf="this.diaryForm.controls['diaryStatus'].value == 2 && statusActionDate != null">
                <label>Signed off on:</label>
                <label class="form-control custom-disable">{{statusActionDate | date : " dd-MM-YYYY hh:mm:ss" }}</label>
            </div>
            <div class="form-group col-md-12">
                <label>Refer Info</label>
                <label class="form-control referinfo-text h-auto text-break custom-disable">{{referInfo}}</label>
            </div>
            <div class="form-group col-md-12">
                <label>Note(max chars 500)</label>
                <textarea #focusInput class="form-control form-control-textarea" formControlName="notes" rows="6" type="text"
                    maxlength="500"></textarea>
            </div>

        </div>
    </div>
    <div class="modal-footer">
        <div class="row gx-2 mt-3 justify-content-end">
            <div class="col-md-auto">
                <button class="btn btn-sm btn-secondary" (click)="closeDialog()">
                    Close
                </button>
            </div>
            <div class="col-md-auto">
                <button class="btn btn-sm btn-primary" (click)="saveDairyDetails()">
                    Save
                </button>
            </div>
        </div>
    </div>
</form>